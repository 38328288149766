import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import homeback1 from "../images/home_back1.png"
import homeback2 from "../images/home_back2.png"
import homeback3 from "../images/home_back3.png"
import homeback4 from "../images/home_back4.png"
import clarity from "../images/clarity.png"
import harmony from "../images/harmony.png"
import proof from "../images/proof.png"
import scale from "../images/scale.png"
import "./index.css"
import Salesforce from "../images/Salesforce.png"
import Slack from "../images/Slack.png"
import Jira from "../images/Jira.png"
import Five9 from "../images/Five9.png"
import Google from "../images/Google.png"
import Quickbooks from "../images/Quickbooks.png"
import Voci from "../images/Voci.png"
import Xero from "../images/Xero.png"
import Roobot from "../images/RooBot.png"
import ourproductbtn from "../images/ourproductbtn.png"
import SEO from "./seo"

export default function Home() {
  return <div>
    <SEO title="Automated talent and performance management"
      description="A roobrick is a smart performance coach that can cut training costs, improve transparency, and keep everyone focused on what matters most"
    ></SEO>
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-md-12 p-0">
          <div style={{ backgroundImage: `url(${homeback1})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionX: 'center' }}>
            <div style={{ minHeight: 150 }}>
              <Header/>
            </div>
            <div style={{ height: 120 }}>
            </div>
            <div>
              <div className="home-hero-div">
                <div className="home-hero-titlelight">Continuous improvement, now on demand</div>
                <div className="home-hero-textlight">A roobrick is a <span style={{ fontFamily: 'Rubik', fontWeight: 'bold' }} >smart performance coach</span> that can
                  cut training costs, improve transparency, and keep everyone focused on what matters most.</div>
              </div>
            </div>
            <div style={{ height: 380, textAlign: 'center' }}>
              <div className="product-btn">
                <a href="/product">
                  <img src={ourproductbtn}/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style={{ height: 150 }}></div>

    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-md-12 p-0">
          <div>
            <div style={{ height: 50 }}></div>
            <div className="home-hero-div">
              <div className="home-hero-titledark">Understands your work</div>
              <div className="home-hero-textdark">
                Your roobrick learns how you work by collecting
                data and peer feedback from within the applications you use every day
              </div>
            </div>
            <div style={{ height: 60 }}></div>
            <div className="saas-apps">
              <div className="saas-app">
                <img src={Slack}/>
              </div>
              <div className="saas-app">
                <img src={Google}/>
              </div>
              <div className="saas-app">
                <img src={Salesforce}/>
              </div>
              <div className="saas-app">
                <img src={Quickbooks}/>
              </div>
            </div>
            <div className="saas-apps">
              <div className="saas-app">
                <img src={Xero}/>
              </div>
              <div className="saas-app">
                <img src={Jira}/>
              </div>
              <div className="saas-app">
                <img src={Voci}/>
              </div>
              <div className="saas-app">
                <img src={Five9}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0">
        <div className="col-md-12">
          <div style={{ height: '2em' }}/>
          <div className="text-center">
            *Lots more.  <a href="contact">Contact us for custom integrations.</a>
          </div>
        </div>
      </div>
    </div>

    <div style={{ height: 180 }}></div>

    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-md-12 p-0">
          <div style={{ backgroundImage: `url(${homeback3})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionX: 'center' }}>
            <div style={{ height: 180 }}></div>
              <div className="home-hero-div">
                <div className="home-hero-titledark">Focused on your performance</div>
                <div className="home-hero-textdark">
                  Your roobrick uses machine learning to transform raw data into useful guidance
                </div>
              </div>
            <div style={{ height: 60 }}></div>
            <div className="home-hero-div">
              <div className="container-fluid home-grid text-left">
                <div className="row">
                  <div className="col-md-5 expad">
                    <div className="home-grid-img">
                      <img src={clarity} className="home-grid-img2"/>
                    </div>
                    <div className="home-grid-title">
                      Clarity
                    </div>
                    <div className="home-grid-text">
                      Whether it's sales closings, operational benchmarks, or customer satisfaction,
                      your roobrick brings your most important performance metrics together in one place,
                      and brings focus to any that could be improved.
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-5 expad">
                    <div className="home-grid-img">
                      <img src={harmony} className="home-grid-img2"/>
                    </div>
                    <div className="home-grid-title">
                      Harmony
                    </div>
                    <div className="home-grid-text">
                      Your roobrick collects feedback from managers, direct reports,
                      and peers both anonymously and continuously,
                      making your 360&deg; review process more honest and effective.
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 expad">
                    <div className="home-grid-img">
                      <img src={proof} className="home-grid-img2"/>
                    </div>
                    <div className="home-grid-title">
                      Training
                    </div>
                    <div className="home-grid-text">
                      Your roobrick comes loaded with help
                      from subject matter experts as well as
                      existing training from your learning management
                      system.
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-5 expad">
                    <div className="home-grid-img">
                      <img src={scale} className="home-grid-img2"/>
                    </div>
                    <div className="home-grid-title">
                      Scale
                    </div>
                    <div className="home-grid-text">
                      Thanks to a library of standard metrics,
                      configuring a new roobrick often takes less time than writing a new training document,
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ height: 100 }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style={{ backgroundImage: `url(${homeback4})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionX: 'center' }}>
      <div style={{ height: 300, textAlign: 'center' }}>
        <div>
          <a href="/product">
            <img src={ourproductbtn}/>
          </a>
        </div>
      </div>
    </div>



    <div style={{ height: 50 }}></div>

    <div className="roobot">
      <img src={Roobot}/>
    </div>
    <br clear="all"/>

    <Footer />

  </div>
}
